import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import data_anaylitics from '../../images/pages/data_anaylitics.png'
import title_image from '../../images/titles/data_and_analytics.jpg'
import person from '../../images/person.png'

const pageTitle = "Data & Analytics";
const pageSlug = "data-and-analytics";
class CloudComputing extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">In today’s market, every business must have access to insight. It’s no longer optional.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Data is an essential corporate asset, and every organization must start treating it like one. Use your data to 
                    the best of its capabilities, to drive business insights, process improvement, automation, and innovation.
                    </h3>
                  <p>Chances are your competitors are using their data for more than just reporting. Your organization can also 
                      become a data leader through the cloud, artificial intelligence, and cutting edge approaches to data systems.
                       By excelling beyond traditional business intelligence, you will be positioned to transform data into 
                       information that accelerates business insight, ensures operational excellence, and allows you to gain
                        a competitive advantage.</p>
                    <p>Not sure where to start? Our experts can help you figure it out.</p>
                   <Link to="/contact-us" className="button green">Get in contact</Link>
                   <span className="break"></span>
                   <h3 className="intext">	Our Data & Analytics Services Can Help You Reach Your Goals.</h3>
                    <span className="break"></span>
                    <img src={data_anaylitics} />
                    <span className="break"></span>
                    <h4>Modern Analytics Platform</h4>
                    <p>Analytics play a crucial and vital role in your future; new services no longer constrain 
                        businesses with expensive data warehousing and large payments.</p>
                    <p>Revolutionize your analytics with the cloud, the latest technologies, and flexible architectures. 
                        Streamline your business insights with data while reducing your costs.</p>
                    <span className="break"></span>
                    <h4>Machine Learning</h4>
                    <p>Your data has deep insights inside it that help create value, yet the predictive power has always 
                        remained elusive. Machine Learning and data science can unlock and grant access to the ability, 
                        and advantages predictability data evaluation can bring. Achieve cost-effective automation solutions 
                        and process improvement through Machine Learning solutions.</p>
                    <span className="break"></span>
                    <h4>Business Intelligence & Visualization</h4>
                    <p>With Business Intelligence and Visualizations, confusing metrics and pages of numbers can be easily 
                        defined to make the right information available and accelerate decision-making processes. Well-designed
                         visualization solutions can enable users to explore more of their metrics and data and aid the 
                         decision-making process. Our experts at Nuerex can help you create a powerful, efficient, 
                         user-friendly and information-rich experience.</p>
                    <span className="break"></span>
                    <h4>Data Quality</h4>
                    <p>Uncertainty around data can make managing your business a significant challenge; problems with discerning 
                        and identifying relevant metrics can impede decision-making processes. Data quality is core in business 
                        definitions, metric formulation, reconciliation of data, and reporting. We can help you reinforce the 
                        quality of your data governance, technology components, and master data management.</p>
                    <span className="break"></span>
                    <h4>Support & Maintenance</h4>
                    <p>Many organizations have difficulty allocating support, time, and skills to solve data solutions. 
                        At Nuerex, we can help manage the solution. We can offer you support and maintenance services for 
                        Data & Analytics solutions.  Our experts can personalize a solution based on your unique requirements.</p>
                    <span className="break"></span>
                    <h3 className="intext">Transform Data into actionable analytics for your organization.</h3>
                    <span className="break"></span>
                    <h4>For insurance</h4>
                    <p>Insurance as an industry depends highly on the maturity of data, Yet there is a limited amount of data 
                        that can improve operations and strategic decision making. Helping you rapidly go from data to solutions.</p>
                    <ul>
                        <li>Underwriting & Operations throughput Tracking</li>
                        <li>Loss Adjustment Expense Optimization</li>
                        <li>Improved Reserving Analysis</li>
                        <li>Agency Performance Analytics</li>
                    </ul>
                    <span className="break"></span>
                    <h4>For Healthcare</h4>
                    <p>Healthcare is a business for improving lives. Coordinated care, EHRs, and targeted interventions are 
                        driving better health in a population. By partnering with us, you can magnify your focus on your 
                        customers, while additionally balancing the goals of your organization.</p>
                    <ul>
                        <li>Data Sharing to Reduce Cost Of Admissions</li>
                        <li>Data-Driven Decisions to Improve Patients’ Treatment</li>
                        <li>Performance And Quality Metrics</li>
                        <li>Decisions Supported By Data to Improve Treatments</li>
                    </ul>
                    <span className="break"></span>
                    <h4>For Energy & Utilities</h4>
                    <p>There is a considerable change in the Utility industry. It is rapidly evolving and changing at an 
                        impressive rate. Renewable energy, information access, smart devices, and energy storage are 
                        revolutionizing this field. Nuerex can help you adjust to the situation.</p>
                    <ul>
                        <li>Dynamic Responsiveness</li>
                        <li>Enhanced Customer Experience</li>
                        <li>Customer Relation Management solutions</li>
                        <li>Voltage Optimizations</li>
                    </ul>
                    <span className="break"></span>
                    <h3 className="intext">Data & Analytics Challenges Organizations are Facing.</h3>
                    <span className="break"></span>
                    <p>Often when the topic of modernization is brought up, businesses respond with “There’s not enough data.”
                         However, when these same organizations ask for new data, it takes far too long and costs far too much. 
                         More time is spent gathering information than understanding what can be done with it.</p>
                    <h6>Are any of the above-taking place in your organization?</h6>
                <p>Building the right supporting functions and correct architecture can be daunting for a variety of reasons:</p>
                <ul>
                    <li>A rise of digital trends</li>
                    <li>Customer Journey experiences</li>
                    <li>Evolving cloud technologies</li>
                    <li>Rising competition with predictive analytics and artificial intelligence</li>
                </ul>
                <span className="break"></span>
                <p><b>The amount of data is only continuing to grow. To protect all your investments, 
                    you need to have a modular and efficient data services and architecture.</b></p>
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default CloudComputing
